$color-nav: #FFFFFF;
$color-nav-active: #2C5BAC;
$color-btn-bg: #2C5BAC;
$color-btn-text: #fff;
$color-btn-text-hover: #2C5BAC;
$color-btn-bg-hover: #fff;
$color-btn-border: #2C5BAC;
$color-bg: #2C5BAC;
$color-bg-second: #EFEFEF;
$color-icon: #fff;
$color-header-invert: #2C5BAC;
$color-cards: #fff;
$color-footer: #2B4471;