@import "colors";
@import "fonts";

* {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    width: 100%;
}

.container {
    width: 90%;
    margin: 0 auto;
}

.hero {
    height: calc(100vh + 20px);
    width: 100%;
}
.hero__cover {
    height: calc(100vh + 20px);
    width: 100%;
    background-image: linear-gradient(45deg, rgba(162,167,178,0.36), rgba(54,57,63,0.36) 70%);
}

.header {
    width: 90%;
    margin: 0 auto;
    padding-top: 30px;
}

.nav__logo {
    float: left;
    width: 200px;
}
.nav__list {
    text-align: right;
    list-style-type: none;
    padding: 24px 0 0 0;
    text-transform: uppercase;
    font-size: 1.5em;
    margin: 0;
}

.nav__list-item {
    display: inline-block;
    text-decoration: none;
}

.nav__list-item--link {
    text-decoration: none;
    color: $color-nav;
    border: 2px solid transparent;
    border-radius: 30px;
    padding: 10px 15px;
    outline: none;
    &:hover {
        border-color: $color-nav-active;
        color: $color-nav-active;
    }
}

.nav-active {
    border-color: $color-nav-active;
    color: $color-nav-active;
}

.burger-container {
    display: none;
}

.slogan {
    width: 90%;
    margin: 0 auto;
    margin: 30vh 0 0 10%;
    width: 50%;
}

.slogan__header {
    font-size: 3em;
    font-weight: 900;
    color: white;
}

.slogan__paragraph {
    font-size: 1.5em;
    line-height: 1.5em;
    margin-bottom: 50px;
    font-weight: 700;
    color: white;
}

.button {
    background-color: $color-btn-bg;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 700;
    color: $color-btn-text;
    padding: 10px 25px;
    border-radius: 30px;
    &:hover {
        color: $color-btn-text-hover;
        background-color: $color-btn-bg-hover;
        border: 2px solid $color-btn-border;
        box-shadow: 0 0 2px 0px $color-btn-border inset, 0 0 2px 0px $color-btn-border;
    }
}

.hello {
    height: 500px;
    display: inline-flex;
}

.hello__left {
    display: flex;
    align-items: center;
    flex-basis: 0;
    flex-grow: 1;
    background-color: #fff;
}
.left__header {
    font-size: 2.5em;
}

.left__paragraph {
    font-size: 1.25em;
    line-height: 1.25em;
}

.hello__right {
    display: flex;
    align-items: center;
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
    background-color: $color-bg;
}

.right__icon-1 {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    display: inline-block;
    margin-left: 20px;
    width: 100px;
    height: 100px;
}

.icon__title {
    text-transform: uppercase;
    color: #fff;
    position: relative;
    top: 30px;
}


.circle-rotate {
    position: absolute;
    top: -20px;
    left: 0;
    height: 100px;
    width: 100px;
    border: 5px solid white;
    border-radius: 50%;
    border-left-color: transparent;
    border-right-color: transparent;
    transform: rotate(45deg);
    transition: transform .5s ease;
    &:hover {
        transform: rotate(360deg);
    }
}

.right__icon {
    height: 50px;
    fill: $color-icon;
    &:first-of-type {
        margin-left: 0;
    }
}

.right__icon--operator {
    height: 30px;
    fill: $color-icon;
    margin-left: 20px;
}

.section-header {
    font-size: 2.5em;
    text-transform: uppercase;
    position: relative;
    padding: 50px 0;
    margin: 0;
}

.why-us {
    background-color: $color-bg-second;
    padding-bottom: 50px;
}

.section-header--invert {
    color: $color-header-invert;
}

.why-us__wrapper {
    display: flex;
}

.why-us__left {
    flex-basis: 0;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.why-us__right {
    background-image: url("../img/why_us.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    flex-basis: 0;
    flex-grow: 1;
    min-height: 500px;
}


.card__container {
    float: left;
    width: 40%;
    height: 200px;
    margin: 20px;
    padding: 20px;
    background-color: $color-cards;
    box-shadow: 0 3px 6px rgba($color: #000000, $alpha: 0.16);
    display: flex;
    justify-content: center;
    align-items: center;
    // cursor: pointer;
    // &:hover {
    //     .card__header {
    //         display: none;
    //     }
    //     .card__paragraph {
    //         display: inline-block;
    //     }
    // }
}

.icon {
    height: 50px;
    width: 50px;
    margin-right: 20px;
    fill: $color-footer;
}

.card__header {
    font-size: 1.5em;
    display: inline-block;
}

.card__paragraph {
    display: none;
}

.calc {
    margin-bottom: 50px;
}

.calc__bcg {
    background-image: url("../img/calc_bcg.jpg");
    width: 100%;
    height: 500px;
    margin-top: 200px;
    padding: 50px 20px;
    text-align: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
}

.calc__woman {
    width: 400px;
    position: absolute;
    bottom: 0;
    left: 15%;
}

.calc__cover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: radial-gradient(#F7F7F7, rgba($color: #808080, $alpha: 0.3));
    width: 100%;
    height: 100%;
    z-index: 1;
}

.calc__form {
    display: inline-block;
    margin-left: 30%;
    margin-top: 100px;
    z-index: 2;
}

.calc__input {
    display: block;
    margin-bottom: 50px;
    font-size: 2em;
    &::placeholder {
        color: #000;
        text-align: center;
    }
}

.calc__radio {
    margin: 0 5px 50px 20px;
}

.radio__label {
    font-size: 2em;
}

.calc__submit {
    display: block;
    margin: 0 auto;
    cursor: pointer;
    border: 2px solid transparent;
}

.offer-slogan {
    background-image: url("../img/offer-slogan.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.offer-slogan__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.offer-slogan__header {
    font-size: 2.5em;
    color: #fff;
    padding: 80px 40px;
    margin: 0;
    position: relative;
    display: inline-block;
    &:before {
        content: '';
        position: absolute;
        top: 10%;
        left: 0;
        height: 80%;
        width: 2px;
        background-color: #fff;
    }
}

.offer-slogan__button {
    font-size: 1.5em;
}

.footer {
    background-color: #2B4471;
    color: #fff;
    font-size: 1.125em;
    padding-top: 30px;
}

.footer__wrapper {
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;
}

.footer__copyright {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footer__header {
    font-size: 1.5em;
    text-transform: uppercase;
}

.footer__menu {
    list-style-type: none;
    padding: 0;
}

.footer__menu-item {
    padding-bottom: 10px;
}

.footer__menu--link {
    color: #fff;
    text-decoration: none;
}

.footer__open-hr {
    display: block;
    margin-bottom: 20px;
}

.footer__welcome {
    text-transform: uppercase;
}

.author {
    background-color: #1C3155;
    color: #fff;
    font-size: 1.125em;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.author__name {
    margin-right: 100px;
}


// About compoany

.header--invert {
    width: 100%;
    margin: 0 auto;
    padding: 0 40px;
    height: 100px;
    padding-top: 10px;
    background-color: #fff;
    box-shadow: 0 3px 6px rgba($color: #000000, $alpha: 0.16);
    z-index: 10;
    position: fixed;
    .nav__logo {
        margin-left: 40px;
    }
    .nav__list {
        margin-right: 40px;
        .nav__list-item--link {
            color: #000;
            &:hover {
                color: $color-nav-active;
            }
        }
        .nav-active {
            color: $color-nav-active;
        }
    }
}

.about__img {
    background-image: url("../img/o-firmie.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 450px;
    position: relative;
}

.cover {
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-color: rgba($color: #07071A, $alpha: 0.57);
}

.about__info {
    height: 650px;
}

.about__wrapper {
    display: flex;
}

.about__info-left {
    flex-basis: 0;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about__info-right {
    flex-basis: 0;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.motto__wrap {
    display: flex;
    justify-content: center;
    align-items: center;
}

.motto__paragraph {
    margin-right: 30px;
}

.motto__title {
    display: inline-block;
    font-size: 24px;
    line-height: 36px;
}

.about__header {
    font-size: 2em;
}

.about__paragraph {
    font-size: 1.125em;
    position: relative;
    padding-bottom: 50px;
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        height: 2px;
        width: 100%;
        background-color: #000;
    }
}

.about__numbers {
    display: flex;
    justify-content: space-around;
    margin-top: 50px;
}

.numb {
    font-size: 2.5em;
    display: block;
    margin-bottom: 10px;
}

.numb__label {
    font-size: 1.125em;
    text-transform: uppercase;
}

.info-left__img {
    margin-top: 50px;
    height: 540px;
    width: 410px;
    background-image: url("../img/o-firmie-img.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    box-shadow: 30px -30px 0 #2C5BAC;
}

.vision {
    background-image: url("../img/lights.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding-bottom: 100px;
    color: #fff;
}

.vision__header {
    padding-top: 50px;
    font-size: 2em;
}

.vision__tiles {
    margin-top: 150px;
    display: flex;
    justify-content: space-around;
}

.tile {
    background-color: #1C3155;
    margin: 50px;
    padding: 20px;
    max-width: 20%;
    min-height: 200px;
    text-align: center;
}

.tile__title {
    font-size: 2em;
    text-transform: uppercase;
    margin: 0 auto;
}

.tile__paragraph {
    font-size: 1.125em;
}

.opinions {
    background-color: #F1F1F1;
}


.opinions__wrapper {
    display: flex;
    height: 100%;
}

.opinions__left {
    flex-basis: 0;
    flex-grow: 1;
    margin-top: 50px;
    padding-bottom: 50px;
}

.opinions__header {
    font-size: 2em;
}

.opinions__right {
    flex-basis: 0;
    flex-grow: 1;
    background-image: url("../img/opinions.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.swiper-container {
    padding: 0 50px;
    z-index: 0;
}

.swiper-wrapper {
    width: 400px;
}

.swiper-slide {
    padding-bottom: 50px;
}

.opinion-slide {
    background-color: #FFFFFF;
    padding: 20px;
    box-shadow: 0 3px 6px rgba($color: #000000, $alpha: 0.16);
    margin-bottom: 50px;
}

.slider__opinion {
    font-style: italic;
}

.slider__name {
    font-weight: bold;
    display: block;
    text-align: right;
}

.together {
    text-align: center;
    margin: 60px 0;
}

.together__header {
    text-transform: uppercase;
    font-size: 2.5em;
    margin-bottom: 30px;
}

.together__paragraph {
    font-size: 2em;
    margin-bottom: 42px;
}

.together__button {
    font-size: 1.5em;
}

.certificates {
    margin-bottom: 50px;
}

.gallery-img {
    height: 200px;
}

.row > .column {
    padding: 25px;
    cursor: pointer;
  }

.row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  
  /* Create four equal columns that floats next to eachother */
  .column {
    float: left;
  }
  
  /* The Modal (background) */
  .modal {
    display: none;
    position: fixed;
    z-index: 1;
    padding-top: 100px;
    margin-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: black;
  }
  
  /* Modal Content */
  .modal-content {
    position: relative;
    // background-color: #fefefe;

    margin: auto;
    padding: 0;
    width: 90%;
    max-width: 1200px;
  }
  
  /* The Close Button */
  .close {
    color: white;
    position: absolute;
    top: 10px;
    right: 25px;
    font-size: 35px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: #999;
    text-decoration: none;
    cursor: pointer;
  }
  
  /* Hide the slides by default */
  .mySlides {
    display: none;
    width: 50%;
    margin: 0 auto;
  }
  
  /* Next & previous buttons */
  .prev,
  .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -50px;
    color: white;
    font-weight: bold;
    font-size: 20px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
    -webkit-user-select: none;
  }
  
  /* Position the "next button" to the right */
  .next {
    right: 0;
    border-radius: 3px 0 0 3px;
  }
  
  /* On hover, add a black background color with a little bit see-through */
  .prev:hover,
  .next:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  /* Number text (1/3 etc) */
  .numbertext {
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
  }
  
  /* Caption text */
  .caption-container {
    text-align: center;
    background-color: black;
    padding: 2px 16px;
    color: white;
  }
  
  img.demo {
    opacity: 0.6;
    height: 200px;
  }
  
  .active,
  .demo:hover {
    opacity: 1;
  }
  
  img.hover-shadow {
    transition: 0.3s;
  }
  
  .hover-shadow:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

// uslugi

.services__img {
    background-image: url("../img/uslugi.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 450px;
}

.services__wrapper {
    display: flex;
    width: 90%;
    height: 100%;
    margin: 0 auto;
}

.services__left {
    flex-basis: 0;
    flex-grow: 1;

}

.services__right {
    flex-basis: 0;
    flex-grow: 2;
    margin: 0;
}

.services__left--img {
    // background-image: url("../img/uslugi-usluga1.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100%;
    box-shadow: -30px 30px 0 #2C5BAC;
}

.ksiegi-handlowe {
    background-image: url("../img/ksiegi-handlowe.jpg");
}

.ksiegi-przychodow {
    background-image: url("../img/uslugi-usluga1.jpg");
}

.ryczalt {
    background-image: url("../img/ryczalt.jpg");
}

.kadry {
    background-image: url("../img/kadry.jpg");
}

.rozliczenia {
    background-image: url("../img/rozliczenia.jpg");
}

.pozostale {
    background-image: url("../img/pozostale.jpg");
}

.space {
    padding: 80px 0;
    margin: 80px 0;
    height: 650px;
}

.service-one {
    margin-top: 0;
}

.service-two {
    background-color: #F1F1F1;
}

.service-four {
    background-color: #F1F1F1;
}

.service-six {
    background-color: #F1F1F1;
}

.service__item {
    margin-bottom: 10px;
}

.img--reverse {
    box-shadow: 30px 30px 0 #2C5BAC;
}

.service__header {
    font-size: 2em;
}

.service__paragraph {
    font-size: 1.125em;
}

.services__reverse {
    flex-direction: row-reverse;
}

// Kontakt

.contact__img {
    background-image: url("../img/contact.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 450px;
}

.contact__header {
    font-size: 3.5em;
}


.contact__info {
    background-color: #EFEFEF;
    height: 600px;
    position: relative;
}

.contact__wrapper {
    background-color: #fff;
    height: 600px;
    position: absolute;
    left: 5%;
    top: -80px;
    width: 90%;
    display: flex;
}

.contact__details {
    flex-basis: 0;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.contact__map {
    flex-basis: 0;
    flex-grow: 1; 
}

.addr {
    display: block;
    font-size: 1.5em;
    padding-bottom: 15px;
    &:nth-of-type(4) {
        padding-left: 55px;
    }
    &:nth-of-type(6) {
        padding-left: 60px;
    }
}

.mail {
    background-image: url("../img/mail.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    text-align: center;
    color: #fff;
}

.mail__cover {
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-color: rgba($color: #07071A, $alpha: 0.79);
}

.mail__form {
    text-align: center;
    width: 30%;
    margin: 0 auto;
}
    
.mail__header {
    font-size: 3.5em;
    margin: 0 0 80px 0;
    padding-top: 50px;
}

.mail__input {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #707070;
    color: #fff;
    padding-bottom: 10px;
    margin-bottom: 30px;
    display: block;
    width: 100%;
    outline: none;
    &::placeholder {
        color: white;
    }
}

.mail__agreement {
    cursor: pointer;
}

.agreement {
    margin-right: 10px;
}

.mail__send {
    border: 2px solid transparent;
    cursor: pointer;
    margin-bottom: 50px;
    display: block;
    margin: 40px auto;
}

.validation-error {
    color: red;
    display: inline-block;
    margin-bottom: 20px;
}

.footer__logo {
    width: 200px;
    margin-bottom: 20px;
}

@media (max-width: 992px) {
    body {
        font-size: 16px;
    }

    .nav__list {
        display: none;
    } 

    .nav__list--mobile {
        display: inline;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color:#fff;
        padding-bottom: 30px;
        .nav__list-item--mobile {
            display: block;
            margin-top: 30px;
            text-align: center;
            color: #000;
            .nav__list-item--link--mobile {
                color: #000;
            }
            .nav-active--mobile {
                color: $color-nav-active;
            }
        }
    }

    .burger-container {
        display: inline-block;
        float: right;
        width: 25px;
        height: 20px;
        cursor: pointer;
        margin-top: 20px;
        margin-right: 10px;
    }
    
    .burger {
        width: 100%;
        height: 100%;
        background-color: transparent;
        position: relative;

    }
    
    .line {
        width: 25px;
        height: 3px;
        background-color: #2C5BAC;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        &:before {
            content: "";
            position: absolute;
            top: -7px;
            left: 50%;
            transform: translate(-50%, 0);
            width: 100%;
            height: 100%;
            background-color: #2C5BAC;
            transition: all 300ms ease-in-out;
        }
        &:after {
            content: "";
            position: absolute;
            top: 7px;
            left: 50%;
            transform: translate(-50%, 0);
            width: 100%;
            height: 100%;
            background-color: #2C5BAC;
            transition: all 300ms ease-in-out;
        }
    }
    
    .burger.active #one {
        background-color: transparent;
    }
    
    .burger.active #one:before {
        top: 0;
        left: 0;
        transform: rotate(45deg);
        background-color: #2C5BAC;
    }
    
    .burger.active #one:after {
        top: 0;
        left: 0;
        transform: rotate(-45deg);
        background-color: #2C5BAC;
    }
    
    .hello {
        flex-direction: column;
        min-height: 700px;
    }
    
    .slogan {
        width: 100%;
        margin: 30vh 0 0 0;
        padding: 0 50px;
        text-align: center;
    }

    .why-us__right {
        display: none;
    }

    .card__container {
        min-height: 200px;
    }

    .why-us__cards {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .circle-rotate {
        display: none;
    }

    .right__icon-1 {
        margin: 0;
    }

    .right__icon--operator {
        margin: 0;
    }

    .card__container {
        width: 100%;
    }

    .calc__woman {
        display: none;
    }

    .calc__form {
        margin: 0;
    }

    .offer-slogan__wrapper {
        flex-direction: column;
        padding-bottom: 50px;
    }

    .offer-slogan__button {
        text-align: center;
    }

    .calc__bcg {
        margin-top: 50px;
    }

    .calc__form {
        padding-top: 150px;
    }


    .footer__wrapper {
        flex-direction: column;
        text-align: center;
    }

    .footer__copyright {
        order: 3;
        margin-top: 50px;
        
    }

    // o firmie

    .numb__label {
        font-size: 1em;
    }

    .about__info-left {
        display: none;
    }

    .vision__tiles {
        flex-direction: column;
    }

    .tile {
        max-width: 100%;
    }

    .opinions__left {
        width: 100%;
    }

    .opinions__right {
        display: none;
    }

    .services__wrapper {
        flex-direction: column;
    }

    .space {
        height: auto;
        margin: 0;
    }

    .services__left {
        order: 2;
        height: 200px;
    }

    .services__left--img {
        margin-top: 20px;
    }

    .service__header {
        margin-top: 10px;
    }

    // kontakt

    .contact__info {
        height: 1000px;
    }

    .contact__wrapper {
        flex-direction: column;
        height: inherit;
    }

    .mail__form {
        width: 90%;
    }
}

@media (min-width: 992px) and (max-width: 1300px) {
    .info-left__img{
        width: 350px;
    }

    .motto__paragraph {
        height: 250px;
    }

    .service__header {
        margin-top: 10px;
    }

    
}

@media (min-width: 970px) and (max-width: 1500px) {
    .why-us__left {
        flex-grow: 2;
    }
    .modal__content {
        width: 50%;
    }
}

@media (max-width: 768px) {
    body {
        font-size: 14px;
    }

    .header--invert .nav__logo {
        margin-left: 0;
        width: 180px;
    }

    .nav__logo {
        width: 180px;
    }

    .motto__paragraph {
        height: 200px;
    }

    .motto__title {
        font-size: 20px;
        line-height: 24px;
    }

    .contact__details {
        padding: 20px;
        align-items: flex-start;
    }
    .contact__header {
        text-align: center;
    }
    .addr {
        font-size: 1.25em;
        &:nth-of-type(4) {
            padding-left: 40px;
        }
        &:nth-of-type(6) {
            padding-left: 40px;
        }
    }
    .modal__content {
        width: 80%;
    }
}

.modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, .4);
}

.modal__content {
    position: fixed;
    top: 25%;
    left: 0;
    right: 0;
    background-color: white;
    width: 50%;
    margin: 0 auto;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.close {
    color: white;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.modal__header {
    background-color: white;
    color: black;
    background-color: #2C5BAC;
    height: 60px;
    h2 {
        line-height: 60px;
        margin: 0;
        color: white;
        text-align: center;
    }
}

.modal__body {
    display: flex;
    .diagram {
        // width: 50%;
    }
}

.result {
    width: 100%;
    margin-top: 50px;
    text-align: center;
    .result__main {
        display: block;
        padding-bottom: 5px;
        margin-bottom: 20px;
        border-bottom: 1px solid lightgray;
    }
}

.calc__bcg {
    .button {
        width: 150px;
    }
}